import React, {useState, useEffect, useCallback} from 'react';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import SpecItem from './SpecItem';
import Button from '../components/Button';
import Text from './Typograhpy/Text';
import Header from './Typograhpy/Header';
import ContentfulImage from './ContentfulImage';
import constants from '../constants';
import Utils from '../util/util';

const MartketListItem = ({
  title,
  description,
  model,
  year,
  thumbnail,
  status,
  onClickBuy,
  onClickMore,
}) => {
  const {t} = useTranslation();

  const getImageWidthForCover = useCallback((breakpoint) => {
    let returnValue;
    switch (breakpoint) {
      case '2xs':
        returnValue = Utils.breakpointSize.xs;
        break;
      case 'xs':
        returnValue = Utils.breakpointSize.sm;
        break;
      case 'sm':
        returnValue = Utils.breakpointSize.md;
        break;
      case 'md':
        returnValue = Utils.breakpointSize.lg;
        break;
      case 'lg':
        returnValue = 466;
        break;
      case 'xl':
        returnValue = 466;
        break;
      default:
        returnValue = 466;
        break;
    }
    return returnValue;
  }, []);

  const [imageOptions, setimageOptions] = useState({
    w: getImageWidthForCover(Utils.getCurrentBreakpoint()),
    fm: 'webp',
  });

  const handleResize = _.debounce(
    () => {
      const sizeOfCurrentBreakpoint = Utils.getSizeOfCurrentBreakpoint(
        Utils.getCurrentBreakpoint()
      );
      if (
        imageOptions.w < sizeOfCurrentBreakpoint &&
        sizeOfCurrentBreakpoint < Utils.breakpointSize.lg
      ) {
        setimageOptions({
          ...imageOptions,
          w: getImageWidthForCover(Utils.getCurrentBreakpoint()),
        });
      }
    },
    1000,
    {trailing: true}
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div
      className={`flex flex-col lg:flex-row w-full h-market-card-sm md:h-market-card lg:h-market-card-lg bg-grey`}
      // style={{height: '325px'}}
    >
      <div
        // className={'w-full h-2/5 lg:w-5/12 lg:h-full'}
        className={'w-full h-1/2 lg:w-5/12 lg:h-full'}
        onClick={onClickMore}
        onKeyPress={onClickMore}
        role={'button'}
        tabIndex={0}
      >
        <ContentfulImage
          imageUrl={_.get(thumbnail, 'url', '')}
          options={imageOptions}
          alt={'image-' + title}
          outterClassName={'w-full h-full'}
          className={'cursor-pointer'}
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{
            scale: 1.1,
          }}
        />
      </div>
      <div className={'p-4 md:p-6 lg:p-6 w-full lg:w-7/12 h-3/5 lg:h-full'}>
        <div className={'flex flex-col'}>
          <Header
            value={title}
            mode={'h2'}
            className={'mb-3 lg:mb-4'}
            truncate
          />
          <Text value={description} className={'mb-4 lg:mb-6'} truncate />
          <div className={'flex mb-4 lg:mb-6 flex-wrap gap-y-2'}>
            <SpecItem
              label={t('car-details.year')}
              value={_.toUpper(year)}
              className={'w-1/2 lg:w-1/3'}
              itemsStart
            />
            <SpecItem
              label={t('car-details.model')}
              value={_.toUpper(model)}
              className={'w-1/2 lg:w-1/3'}
              itemsStart
            />
            <SpecItem
              label={t('car-details.status')}
              value={_.toUpper(status)}
              className={'w-1/2 lg:w-1/3'}
              itemsStart
            />
          </div>
          <div className={'flex gap-x-0 lg:gap-x-8'}>
            <div className={'w-1/2 lg:w-auto'}>
              <Button
                title={t('button.more')}
                second
                onClickBtn={onClickMore}
              />
            </div>
            <div className={'w-1/2 lg:w-auto'}>
              <Button
                title={t('button.bring-it-home')}
                onClickBtn={onClickBuy}
                disabled={status !== constants.CAR_STATUS.AVAILABLE}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MartketListItem;

MartketListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  model: PropTypes.string,
  year: PropTypes.number,
  onClickBuy: PropTypes.func,
  onClickMore: PropTypes.func,
};
MartketListItem.defaultProps = {
  model: null,
  year: null,
  onClickBuy: () => {},
  onClickMore: () => {},
};
