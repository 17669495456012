import React, {useState, useEffect, useMemo, useCallback} from 'react';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
// import PropTypes from 'prop-types';
import {graphql, navigate} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import ContentContainer from '../../components/ContentContainer';
import SectionHeader from '../../components/SectionHeader';
import PageDivider from '../../components/PageDivider';
import Spacer from '../../components/Spacer';
import constants from '../../constants';
import Button from '../../components/Button';
import MartketListItem from '../../components/MarketListItem';
import {useContentfulQuery} from '../../services/CustomHooks';
import {GET_MARKET_CAR_COLLECTION} from '../../util/graphQuery';
import configs from '../../configs';
import Loading from '../../components/Loading';

const Market = ({data}) => {
  const {
    loading,
    data: cData,
    fetchMore,
  } = useContentfulQuery(GET_MARKET_CAR_COLLECTION, {
    order: constants.CONTENTFUL_GRAPHQL.ORDER.FIRST_PUBLISHED_AT_ASC,
    variables: {
      limit: configs.pagination.marketPlace,
      offset: 0,
    },
  });
  const [carList, setcarList] = useState([]);
  const [cursor, setcursor] = useState(0);
  const [isFetchingMore, setisFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setcarList(_.get(cData, 'carCollection.items', []));
    setcursor(_.size(_.get(cData, 'carCollection.items', [])));
  }, [cData]);

  const {image} = data;

  const _onClickMore = (cid) => {
    if (!cid) return;
    navigate(`/car/${cid}`);
  };

  const _onClickBuy = (car) => {
    const carStatus = _.get(car, 'status', '');
    switch (carStatus) {
      case constants.CAR_STATUS.AVAILABLE: {
        const message = `Interested Car:\t${_.get(
          car,
          'title',
          ''
        )}\nModel:\t${_.get(car, 'model', '')}\nYear:\t${_.get(
          car,
          'year',
          ''
        )}\n\n`;
        navigate('/Contact', {
          state: {
            queryType: constants.QUERY_TYPE.CAR_PURCHASE,
            message,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  const handleFetchItems = () => {
    if (!isFetchingMore && hasMore) {
      setisFetchingMore(true);
      const limit = configs.pagination.marketPlace;
      const offset = cursor;

      fetchMore({
        variables: {
          limit,
          offset,
        },
      })
        .then((result) => {
          const {data: _cData} = result;
          const newCarList = _.get(_cData, 'carCollection.items', []);
          const newCarListLength = _.size(newCarList);

          if (newCarListLength < configs.pagination.marketPlace) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setcarList([...carList, ...newCarList]);
          setcursor(carList.length + newCarListLength);
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => setisFetchingMore(false));
    }
  };

  const _renderListItem = useCallback((i, idx) => {
    return (
      <MartketListItem
        key={`market-list-item-${idx}`}
        title={i.title}
        description={i.description}
        model={i.model}
        year={i.year}
        status={i.status}
        thumbnail={i.thumbnail}
        onClickBuy={() => _onClickBuy(i)}
        onClickMore={() => _onClickMore(_.get(i, 'sys.id', null))}
      />
    );
  }, []);

  const _memorizedRenderCarList = useMemo(() => {
    return (
      <div className={'flex flex-col gap-10 min-h-72'}>
        {_.map(carList, _renderListItem)}
        {(loading || isFetchingMore) && <Loading />}
      </div>
    );
  }, [carList, _renderListItem, loading, isFetchingMore]);

  return (
    <>
      <Helmet>
        <title>Auto Savvy HK | Market</title>
      </Helmet>
      <Spacer />
      <PageDivider image1={getImage(image)} />
      <div className={'py-10 lg:py-20'}>
        <ContentContainer className={''}>
          <SectionHeader title={'Marketplace'} />

          {_memorizedRenderCarList}
          <div className={`w-full mt-16 flex justify-center items-center`}>
            {hasMore && !loading && (
              <Button
                title={'Load More'}
                onClickBtn={handleFetchItems}
                disabled={isFetchingMore}
              />
            )}
          </div>
        </ContentContainer>
      </div>
    </>
  );
};

export default Market;

export const query = graphql`
  query HomePageQuery {
    # allContentfulCar(filter: {node_locale: {eq: "en-US"}}) {
    #   edges {
    #     node {
    #       description
    #       model
    #       status
    #       year
    #       title
    #       contentful_id
    #       thumbnail {
    #         gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    #       }
    #     }
    #   }
    # }
    image: file(name: {eq: "service02"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`;

Market.propTypes = {};
Market.defaultProps = {};
